export const Snowboard = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h3 className='title'>
            Сноуборд
          </h3>
        </div>
        <div className='row'>
          <div className='col-md-4'>
            <div className='icon'>
              <img
                src="img/instructors/check2.jpg"
                className='img-responsive'

              >
              </img>
            </div>
            <div className='service-desc'>
              <h3>Нестеров Максим</h3>
              <p>Профиль работы: обучение катанию на сноуборде.</p>
              <p>Взрослые и дети (с 7 лет).</p>
              <p>Категория: 3. Прошел обучение у УЦ ФГССР России в 2021г.</p>
              <p>Стаж преподавания: с 2018 г.</p>
              <p>Стаж индивидуального катания: с 2017 года.</p>
              <p>Вид занятий: индивидуальные тренировки / групповые (для лиц с навыком катания). </p>
              <p>
                <a href="https://rubitime.ru/api/get-project-static/8f5c8f10bfa3f0f19bb2bed015b8e45661c832f11949f18e9ffb8b5b0cfd3d1b/4775/20803" className="btn btn-custom btn-lg page-scroll">Записаться</a>

              </p>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='icon'>
              <img
                src="img/instructors/check14.jpg"
                className='img-responsive'

              >
              </img>
            </div>
            <div className='service-desc'>
              <h3>Багулин Дмитрий</h3>
              <p>Профиль работы: обучение катанию на сноуборде.</p>
              <p>Взрослые и дети (с 7 лет).</p>
              <p>Категория: 3. Прошел обучение у УЦ ФГССР России в 2021г.</p>
              <p>Стаж преподавания: с 2017 г.</p>
              <p>Стаж индивидуального катания: с 2013 года.</p>
              <p>Вид занятий: индивидуальные, Также возможны групповые тренировки для людей с навыком катания.</p>
              <p>
                <a href="https://rubitime.ru/api/get-project-static/8f5c8f10bfa3f0f19bb2bed015b8e45661c832f11949f18e9ffb8b5b0cfd3d1b/4775/20803" className="btn btn-custom btn-lg page-scroll">Записаться</a>

              </p>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='icon'>
              <img
                src="img/instructors/check4.jpg"
                className='img-responsive'

              >
              </img>
            </div>
            <div className='service-desc'>
              <h3>Торсунов Матвей</h3>
              <p>Профиль работы: обучение катанию на сноуборде.</p>
              <p>Взрослые и дети (с 7 лет).</p>
              <p>Категория: С. Прошел обучение в Национальной Лиге инструкторов 2024г</p>
              <p>Стаж преподавания: с 2018 г.</p>
              <p>Стаж индивидуального катания: с 2014 года.</p>
              <p>Вид занятий: индивидуальные тренировки, групповые тренировки.</p>
              <p>
                <a href="https://rubitime.ru/api/get-project-static/8f5c8f10bfa3f0f19bb2bed015b8e45661c832f11949f18e9ffb8b5b0cfd3d1b/4775/20803" className="btn btn-custom btn-lg page-scroll">Записаться</a>

              </p>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='icon'>
              <img
                src="img/instructors/check16.jpeg"
                className='img-responsive'

              >
              </img>
            </div>
            <div className='service-desc'>
              <h3>Небользин Илья</h3>
              <p>Профиль работы: обучение катанию на сноуборде.</p>
              <p>Взрослые и дети (с 5 лет).</p>
              <p>Пройдено обучение в Национальной Лиге инструкторов 2024г.</p>
              <p>Стаж преподавания: с 2023 г.</p>
              <p>Стаж индивидуального катания: с 2017 года.</p>
              <p>Вид занятий: индивидуальные тренировки.</p>
              <p>
                <a href="https://rubitime.ru/api/get-project-static/8f5c8f10bfa3f0f19bb2bed015b8e45661c832f11949f18e9ffb8b5b0cfd3d1b/4775/20803" className="btn btn-custom btn-lg page-scroll">Записаться</a>

              </p>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='icon'>
              <img
                src="img/instructors/check18.jpg"
                className='img-responsive'

              >
              </img>
            </div>
            <div className='service-desc'>
              <h3>Тэн Ксения</h3>
              <p>Профиль работы: обучение катанию на сноуборде.</p>
              <p>Взрослые и дети (с 5 лет).</p>
              <p>Категория: 3. Прошел обучение у УЦ ФГССР России в 2021г.</p>
              <p>Стаж преподавания: с 2023 г.</p>
              <p>Стаж индивидуального катания: с 1998 года.</p>
              <p>Вид занятий: индивидуальные тренировки.</p>
              <p>
                <a href="https://rubitime.ru/api/get-project-static/8f5c8f10bfa3f0f19bb2bed015b8e45661c832f11949f18e9ffb8b5b0cfd3d1b/4775/20803" className="btn btn-custom btn-lg page-scroll">Записаться</a>

              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
